import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (...arr) => {
      if (localStorage.getItem('token')) {
        arr[2]('petition');
        return;
      }
      arr[2]('/login');
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'../views/LoginView.vue'),
    beforeEnter: (...arr) => {
      if (localStorage.getItem('token')) {
        arr[2]('petition');
        return;
      }
      arr[2]();
    }
  },
  {
    path: '/petition',
    name: 'petition',
    component: () => import(/* webpackChunkName: "petition" */ '../views/PetitionView.vue'),
    beforeEnter: (...arr) => {
      console.log(localStorage.getItem('token'));
      if (localStorage.getItem('token')) {
        arr[2]();
        return;
      }
      arr[2]('/login');
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "petition" */ '../views/UploadList.vue'),
    beforeEnter: (...arr) => {
      console.log(localStorage.getItem('token'));
      if (localStorage.getItem('token')) {
        arr[2]();
        return;
      }
      arr[2]('/login');
    }
  }
]
console.log(1111, process.env.NODE_ENV);

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV !== 'production' ? process.env.BASE_URL : '/intelligent'),
  routes
})

export default router
