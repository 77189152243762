/**
 * 从localStorage获取token
 */
// import Cookies from 'js-cookie';

const getToken = () => {
  return localStorage.getItem('token');
};

const getRoleId = () => {
  return localStorage.getItem('roleId');
};

const setRoleId = (id: string) => {
  return localStorage.setItem('roleId', id);
};

/**
 * 往localStorage存储token
 */
const setToken = (token: string) => {
  // Cookies.set('Auth', token, { expires: 7 });
  localStorage.setItem('token', token);
};

/**
 * 清空localStorage中的token
 */
const clearToken = () => {
  localStorage.removeItem('roleId');
  localStorage.removeItem('token');
};

/**
 * 判断是否登录
 */
const isLogin = () => {
  return true;
  // return localStorage.getItem('token') !== '';
};

export { clearToken, getRoleId, getToken, isLogin, setRoleId, setToken };
