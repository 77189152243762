import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import TeleVue, { TeleBizVue } from '@tele-design/web-vue'
import '@tele-design/web-vue/dist/tele.css'
import TeleVueIcon from '@tele-design/web-vue/es/icon'; // 引入icon图标

import $http from '@/utils/http'
import '@tele-design/web-vue/dist/tele.css'

const app = createApp(App);

app.use(TeleVue);
app.use(TeleVueIcon);
app.use(TeleBizVue, { $http });

app.use(store).use(router).mount('#app')
